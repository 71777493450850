import { useWeb3React } from '@web3-react/core';
import { useEffect, useState, useRef } from 'react';
import toast from 'react-hot-toast';
import { getEngineInfo, purchaseWhitelist } from 'utils/contracts';
// import { NFTMintEngineDetail } from 'utils/typs';
import Timer from '../timer/Timer';
import './whiteMint.scss'

type Loading = {
    setIsLoading(value: boolean) : void
}

export default function WhiteMint({setIsLoading}:Loading) {
    const [showMint, setShowMint] = useState(false)
    const [mintCount, setMintCount] = useState(1);

    const numRef = useRef();
    const decreaseHandle = () => {
        if (mintCount > 1) {
            setMintCount(mintCount - 1)
        }

    }
    const increaseHandle = () => {
        if (mintCount < 20) {
            setMintCount(mintCount + 1)
        }
    }

    const [loginStatus, setLoginStatus] = useState(false);
    const { connector, library, chainId, account, active } = useWeb3React();
    // const [mintEngineDetail, setMintEngineDetail] = useState<NFTMintEngineDetail>(null);


    const [imgCount, setImgCount] = useState(0)
    const handleLoad = ()=>{
        setImgCount(imgCount + 1)
    }

    useEffect(() => {
        const isLoggedin = account && active && chainId === parseInt(process.env.REACT_APP_NETWORK_ID, 10);
        setLoginStatus(isLoggedin);
        getEngineInfo(chainId, library).then(
            // (nftMintEngineDetail: NFTMintEngineDetail) => {
            //     setMintEngineDetail(nftMintEngineDetail);
            // }
        );

        if (imgCount >= 1){
            setIsLoading(false)
        }


    }, [connector, library, account, active, chainId, setIsLoading, imgCount]);




    const mintTokens = async () => {
        if (!loginStatus) {
            toast.error("Please connect wallet correctly!");
            return;
        }

        if (mintCount <= 0) {
            toast.error("Mint Count should be over than 0");
            return;
        }
        const load_toast_id = toast.loading("Please wait for Mint...");
        try {
            const bSuccess = await purchaseWhitelist(chainId, library.getSigner(), account, mintCount);
            if (bSuccess) {
                toast.success("Mint Success!");
            } else {
                toast.error("Mint Failed!");
            }
        } catch (error) {
            toast.error("Mint Failed!");
        }
        toast.dismiss(load_toast_id);
    };
    const [isDown, setIsDown] = useState('')
    const handleDown=(value:string)=>{
        setIsDown(value)
        console.log(isDown)
    }
    const handleUp=(value:string)=>{
        setIsDown(value)
        console.log(isDown)
    }

    

    return (
        <div className="whiteMint" >
            <div className="scroll" id='whiteMint'> </div>
                <div className="mintContent" style={{backgroundImage : `url('assets/border_04.png')`}}>
                    <img src="assets/mint_top_01.png" alt="" className="topImg" onLoad={handleLoad} />
                    <h1 data-aos="fade-up">WhiteList GOAT NFTS</h1>
                    <div className="line1"></div>
                    
                    <div className="mintWrapper">
                        <div className="mintPart" >

                            {showMint === false ?
                            <>
                                <div className="countDown">
                                <h2>Time Till Mint</h2>
                                <p>Date: Tue, Mar 22th, 12:00 GMT</p>
                                    {/* <Timer deadLine={1647950400} setShowMint={()=>{setShowMint(true)}}/> */}
                                    <Timer deadLine={0} setShowMint={() => { setShowMint(true) }} />
                                </div>
                            </>:
                            <>
                                <h2>MINT WhiteList GOAT NFTs</h2>
                                <div className="mintCount">
                                    <button className={isDown === 'decDwon' ? "mintDecrease decDwon":"mintDecrease"}
                                        onMouseDown={()=>{handleDown('decDwon')}}
                                        onMouseUp={()=>{handleUp('')}}
                                        onClick={decreaseHandle}>
                                            <i className="fas fa-minus"></i>
                                    </button>

                                    <span className="mintCountValue" ref = {numRef} style={{}}>{mintCount}</span>
                                    <button className={isDown === 'incDwon' ? "mintIncrease incDwon":"mintIncrease"}
                                        onMouseDown={()=>{handleDown('incDwon')}}
                                        onMouseUp={()=>{handleUp('')}}
                                        onClick={increaseHandle} >
                                            <i className="fas fa-plus"></i>
                                    </button>
                                    <button className={isDown === 'btnDwon' ? "mintNow button mintDwon button1":"mintNow button button1"}
                                        onClick={mintTokens}
                                        onMouseDown={()=>{handleDown('btnDwon')}}
                                        onMouseUp={()=>{handleUp('')}}
                                    >MINT</button>
                                </div>
                                </>}
                        </div>

                    </div>
                    <img src="assets/goat.png" alt="" className="goat_01" />
                    <img src="assets/goat_02.png" alt="" className="goat_02" />
            </div>
        </div>
    )
}

