import "./footer.scss";
export default function Footer() {
  return (
    <div className="footer">
      <div className="footerContent">
        <div className="socialLinks">
          <a
            href="https://discord.gg/DAWfucJ7mN"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-discord"></i>
          </a>
          <a
            href="https://twitter.com/GOATLifeNFT"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-twitter"></i>
          </a>
          <a
            href="https://www.instagram.com/goatlife_nft/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-instagram"></i>
          </a>
          <a
            href="https://opensea.io/collection/goat-traders-life"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              id="aa03f514-7f9b-48f0-af9c-54bfb328d596"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 90 90"
            >
              <path
                className="f0f78ce7-4b4a-4673-a687-b4db3d4e687b"
                d="M270.78,305.06a45,45,0,1,1-45-45A45,45,0,0,1,270.78,305.06Z"
                transform="translate(-180.78 -260.06)"
              />
              <path
                className="ab95ed25-cf14-4d8d-b49f-a1c4e14b8ea0"
                d="M203,306.57l.19-.3L214.88,288a.39.39,0,0,1,.7.05c2,4.38,3.64,9.83,2.85,13.22a20.77,20.77,0,0,1-2.3,5,8,8,0,0,1-.44.74.4.4,0,0,1-.33.18h-12A.4.4,0,0,1,203,306.57Z"
                transform="translate(-180.78 -260.06)"
              />
              <path
                className="ab95ed25-cf14-4d8d-b49f-a1c4e14b8ea0"
                d="M255.16,310v2.89a.42.42,0,0,1-.25.38c-.91.39-4,1.82-5.3,3.61-3.29,4.58-5.81,11.13-11.43,11.13H214.72a15.07,15.07,0,0,1-15-15.1v-.26a.4.4,0,0,1,.4-.41h13.07a.45.45,0,0,1,.43.5,4.5,4.5,0,0,0,.46,2.51,4.62,4.62,0,0,0,4.13,2.56h6.47v-5.05h-6.4a.41.41,0,0,1-.33-.65l.23-.34c.61-.86,1.47-2.2,2.33-3.72a27.63,27.63,0,0,0,1.61-3.22c.1-.2.17-.4.25-.6.12-.36.25-.68.34-1s.17-.57.24-.84a13,13,0,0,0,.31-2.95c0-.41,0-.83-.05-1.23s-.08-.88-.13-1.32-.11-.77-.18-1.17c-.1-.59-.22-1.17-.37-1.76l-.05-.22c-.11-.4-.21-.78-.34-1.19-.36-1.26-.78-2.49-1.23-3.64-.16-.46-.34-.9-.53-1.34-.27-.66-.55-1.26-.8-1.83-.13-.26-.24-.49-.35-.73s-.26-.55-.39-.81-.2-.38-.27-.57l-.79-1.46a.25.25,0,0,1,.29-.37l4.95,1.34h0l.65.18.72.21.26.07v-2.94a2.56,2.56,0,0,1,2.54-2.57,2.51,2.51,0,0,1,1.8.75,2.6,2.6,0,0,1,.75,1.82V285l.52.14.12.06.55.42c.19.15.39.33.63.51.48.39,1,.88,1.67,1.45.16.14.33.29.47.44.81.75,1.71,1.63,2.57,2.6q.36.4.72.84c.24.3.49.59.71.88s.61.79.88,1.21c.13.2.28.4.4.6a16.94,16.94,0,0,1,1,1.63c.13.26.26.53.37.81a12.45,12.45,0,0,1,.75,2.23,3.11,3.11,0,0,1,.11.49v0a5.14,5.14,0,0,1,.09.7,7.51,7.51,0,0,1-.13,2.31,8.62,8.62,0,0,1-.27,1c-.11.32-.23.65-.37,1a13,13,0,0,1-1,1.88l-.42.68-.48.68c-.2.27-.42.57-.64.82a7,7,0,0,1-.63.79c-.31.37-.6.71-.91,1s-.39.44-.59.64-.4.42-.59.61-.57.55-.78.75l-.51.46a.39.39,0,0,1-.27.11h-3.94v5.05h5a4.65,4.65,0,0,0,3-1.11,37.74,37.74,0,0,0,3.06-3,.37.37,0,0,1,.19-.11l13.69-4A.4.4,0,0,1,255.16,310Z"
                transform="translate(-180.78 -260.06)"
              />
            </svg>
          </a>
        </div>

        <span>© 2022 G.O.A.T LIFESTYLE. All right reserved.</span>
      </div>
    </div>
  );
}
