import "@ethersproject/shims"
import { BigNumber} from "ethers";
import toast from "react-hot-toast";
import { getContractObj } from ".";
import { NFTMintEngineDetail } from "./typs";
import PresaleWhiteListMap from "./PresaleWhiteListMap.json";

export async function purchaseWhitelist(chainId, provider, account, numberOfTokens) {
    const GoatTradersContract = getContractObj('GoatTraders', chainId, provider);
    try {
        const index = PresaleWhiteListMap["claims"][account]?.index;
        const amount = PresaleWhiteListMap["claims"][account]?.amount;
        const proof = PresaleWhiteListMap["claims"][account]?.proof;

        if (index === undefined || amount === undefined || proof === undefined) {
            toast.error("You are not registered to whitelist.");
            return false;
        }

        var big_index: BigNumber = BigNumber.from(index);
        var big_amount: BigNumber = BigNumber.from(amount);

        const tx = await GoatTradersContract.mintWhitelist(numberOfTokens, big_index, big_amount, proof);
        await tx.wait(1);

        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
}

export async function purchase(chainId, provider, account, numberOfTokens) {
    const GoatTradersContract = getContractObj('GoatTraders', chainId, provider);
    try {
        var nftPrice: BigNumber = await GoatTradersContract.COST();
        const tx = await GoatTradersContract.mint(numberOfTokens, {
            value: nftPrice.mul(numberOfTokens)
        });
        await tx.wait(1);

        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
}

export async function getEngineInfo(chainId, library) {
    const GoatTradersContract = getContractObj('GoatTraders', chainId, library);
    try {
        const [
            totalSupply,
            ownerAddress,
        ] = await Promise.all([
            GoatTradersContract.totalSupply(),
            GoatTradersContract.owner(),
        ]);

        const nftMintDetail: NFTMintEngineDetail = {
            totalSupply: parseInt(totalSupply.toString()),
            ownerAddress: ownerAddress.toString(),
        }

        return nftMintDetail;
    } catch (e) {
        console.log(e);
        return null;
    }
}

export async function getAccountNFTBalance(chainId, library, account) {
    const GoatTradersContract = getContractObj('GoatTraders', chainId, library);
    try {
        const NFTBalance = await GoatTradersContract.balanceOf(account);
        return parseInt(NFTBalance.toString());
    } catch (e) {
        console.log(e);
        return 0;
    }
}

