import { useState } from "react";
import "./about.scss";
export default function About() {
  const [tabID, setTabID] = useState(0);
  return (
    <div className="about">
      <div className="scroll" id="about">
        {" "}
      </div>
      <div
        className="aboutContent"
        style={{ backgroundImage: `url('assets/border_02.png')` }}
      >
        <div className="wrapper">
          <div className="title">
            <h1 data-aos="fade-up">Real Life Value</h1>
            <h1 data-aos="fade-up">Trading Community, Guaranteed Buyback,</h1>
            <h1 data-aos="fade-up">Staking and Rewards</h1>
          </div>
          <div data-aos="fade-up" className="line1"></div>
          <p data-aos="fade-up">
            <span>
              The GOAT Life NFT project was created in response to the perceived
              value in the NFT space. The idea was to create NFTs that have real
              value beyond the perceived value of the artwork.
            </span>
          </p>
          <div className="myTab">
            <div className="tabList">
              <div
                className={`${tabID === 0 ? "activeTab tab" : "tab"}`}
                onClick={() => setTabID(0)}
              >
                Trading Community*
              </div>
              <div
                className={`${tabID === 1 ? "activeTab tab" : "tab"}`}
                onClick={() => setTabID(1)}
              >
                Guaranteed liquidity
              </div>
              <div
                className={`${tabID === 2 ? "activeTab tab" : "tab"}`}
                onClick={() => setTabID(2)}
              >
                Staking
              </div>
              <div
                className={`${tabID === 3 ? "activeTab tab" : "tab"}`}
                onClick={() => setTabID(3)}
              >
                Ascension Trading Bots*
              </div>
              <div
                className={`${tabID === 4 ? "activeTab tab" : "tab"}`}
                onClick={() => setTabID(4)}
              >
                Giving Back
              </div>
            </div>
            {tabID === 0 && (
              <div className="tabContent">
                <div className="col-div">
                  <h3>
                    The GOAT Life NFT gives the holder access to the GOAT Life
                    Trading Community and all of Crypto Chef B’s charts, low cap
                    gem research, and insider information.{" "}
                    <span>
                      This Trading Community includes trade set-ups from a
                      successful algorithm and a copytrader.
                    </span>
                  </h3>
                  <p>
                    *All information shared in the Trading Community is the
                    personal opinion of the one sharing, and is never financial
                    advice. Always do your own research and due diligence.
                  </p>
                </div>
              </div>
            )}
            {tabID === 1 && (
              <div className="tabContent">
                <div className="col-div">
                  <h3>
                    GOAT Life NFTs were set up with real value beyond being a
                    piece of digital art and entrance to an exclusive community
                    by using 50% of the NFT sales revenues to guarantee
                    liquidity for owners. 90days after selling out, GOAT Life
                    NFT holders will have the option to keep their NFT and stake
                    it, burn it for the redemption value, or trade it on our
                    marketplace or Open Sea.
                  </h3>
                  <h3>
                    <span>
                      Every GOAT Life NFT comes with an attached redemption
                      value.{" "}
                    </span>
                    If holders burn and redeem their NFT, they will receive the
                    attached Guaranteed BuyBack Value of the NFT or Wrapped ETH
                    equivalent.
                  </h3>
                </div>
              </div>
            )}
            {tabID === 2 && (
              <div className="tabContent">
                <div className="col-div">
                  <h3>
                    Staking will be available 90days after the first collection
                    of GOAT Life NFTs sells out.{" "}
                    <span>
                      Owners that skate their NFT will get rewarded airdrops $
                      GOAT tokens
                    </span>{" "}
                    that can be used for purchasing merchandise playing Daily
                    Fantasy contests, entering raffles, and paying for future
                    products and services available through partnering projects.
                  </h3>
                </div>
              </div>
            )}
            {tabID === 3 && (
              <div className="tabContent">
                <div className="col-div">
                  <h3>
                    GOAT Life NFT owners will have access to some of the{" "}
                    <span>top trading boats and algorithms </span> to make
                    trading less stressful with potential passive income.
                  </h3>
                </div>
              </div>
            )}
            {tabID === 4 && (
              <div className="tabContent">
                <div className="col-div">
                  <h3>
                    From the beginning, GOAT Traders and GOAT Life NFT was about
                    helping other people. A percentage of the sale of each NFT
                    collection will benefit the non-profit organization, The
                    Surfing Goats. Dana and The Surfing Goats travel and put on
                    Free Surf Camps for Special-needs Individuals and Groups. We
                    are excited to partner with such an amazing group bringing
                    joy to this world. The deposit or transfer will be posted on
                    the website for full transparency of the project. Check out
                    their website{" "}
                    <a
                      href="www.SurfingGoats.com"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      www.SurfingGoats.com
                    </a>
                  </h3>
                  <h3>
                    <span>
                      The GOAT Life Community will nominate and vote on future
                      charity partners for donations from future collections.
                    </span>
                  </h3>
                  <p>
                    *We give asscess to trading boats with a successful track
                    record, but there is always financial risk and there is
                    never any guarantee of profit or reward.
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
