import "./story.scss";
export default function Story() {
  return (
    <div className="story">
      <div className="scroll" id="story">
        {" "}
      </div>
      <div
        className="storyContent"
        style={{ backgroundImage: `url('assets/border_03.png')` }}
      >
        <div className="wrapper">
          <div className="title">
            <h1 data-aos="fade-up">Our Story</h1>
          </div>
          <div data-aos="fade-up" className="line1"></div>
          <p data-aos="fade-up">
            <span>
              You don’t have to be Michael Jordan, Tom Brady, or Beyonce to be a
              GOAT.
            </span>{" "}
            Being a GOAT is about being the greatest version of yourself. Trying
            your best to make each moment, any interaction and every second into
            the Greatest of All Time naturally leads to the GOAT Life.
          </p>
          <p data-aos="fade-up">
            <span>GOAT Traders started as an Trading Community,</span> a group
            dedicated to sharing personal successful trade set-ups to
            collaborate with others on the way to making life changing money. It
            was a group sharing leads about presales, DeFi, NFTs and more.
          </p>
          <p data-aos="fade-up">
            <span>We saw crypto as a way to living our GOAT Life.</span> We
            wanted to share our ideas with others, and make life changing money
            together. We want to have a community sharing and collaborating, and
            we wanted to take it further and help others and give to others.
          </p>
          <p data-aos="fade-up">
            For us, living the GOAT Life isn’t just about making millions and
            driving a lambo.
            <span>
              But it is about using money to make a difference in the world
              around us, our families and communities.
            </span>{" "}
          </p>
          <div className="bottom">
            <img src="assets/story.png" alt="" />
            <h3>What’s your GOAT Life?</h3>
            <p>
              Maybe you do want a Lambo! Do you want to buy an island? Do you
              want additional income to spend time with family and make
              memories? Do you want a set-up that generates funds for good
              causes?
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
