import { useWeb3React } from "@web3-react/core";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { purchase } from "utils/contracts";
// import { NFTMintEngineDetail } from "utils/typs";
import Timer from "../timer/Timer";
import "./mint.scss";
import { HashLink } from "react-router-hash-link";

type Loading = {
  setIsLoading(value: boolean): void;
};

export default function Mint({ setIsLoading }: Loading) {
  const [showMint, setShowMint] = useState(false);
  const [mintCount, setMintCount] = useState(1);

  const numRef = useRef();
  const decreaseHandle = () => {
    if (mintCount > 1) {
      setMintCount(mintCount - 1);
    }
  };
  const increaseHandle = () => {
    if (mintCount < 3) {
      setMintCount(mintCount + 1);
    }
  };

  const [loginStatus, setLoginStatus] = useState(false);
  const { connector, library, chainId, account, active } = useWeb3React();
  //   const [mintEngineDetail, setMintEngineDetail] =
  //     useState<NFTMintEngineDetail>(null);

  const [imgCount, setImgCount] = useState(0);
  const handleLoad = () => {
    setImgCount(imgCount + 1);
  };

  useEffect(() => {
    const isLoggedin =
      account &&
      active &&
      chainId === parseInt(process.env.REACT_APP_NETWORK_ID, 10);
    setLoginStatus(isLoggedin);
    // getEngineInfo(chainId, library).then(
    //   (nftMintEngineDetail: NFTMintEngineDetail) => {
    //     setMintEngineDetail(nftMintEngineDetail);
    //   }
    // );

    if (imgCount >= 4) {
      setIsLoading(false);
    }
  }, [connector, library, account, active, chainId, setIsLoading, imgCount]);

  const mintTokens = async () => {
    if (!loginStatus) {
      toast.error("Please connect wallet correctly!");
      return;
    }

    if (mintCount <= 0) {
      toast.error("Mint Count should be over than 0");
      return;
    }
    const load_toast_id = toast.loading("Please wait for Mint...");
    try {
      const bSuccess = await purchase(
        chainId,
        library.getSigner(),
        account,
        mintCount
      );
      if (bSuccess) {
        toast.success("Mint Success!");
      } else {
        toast.error("Mint Failed!");
      }
    } catch (error) {
      toast.error("Mint Failed!");
    }
    toast.dismiss(load_toast_id);
  };
  const [isDown, setIsDown] = useState("");
  const handleDown = (value: string) => {
    setIsDown(value);
    console.log(isDown);
  };
  const handleUp = (value: string) => {
    setIsDown(value);
    console.log(isDown);
  };

  return (
    <div className="mint">
      <div className="scroll" id="mint">
        {" "}
      </div>
      <div
        className="mintContent"
        style={{ backgroundImage: `url('assets/border_03.png')` }}
      >
        <img
          src="assets/mint_top_01.png"
          alt=""
          className="topImg"
          onLoad={handleLoad}
        />
        <h1 data-aos="fade-up">GOAT LIFE NFTS</h1>
        <div className="line1" data-aos="fade-up"></div>
        <p data-aos="fade-up">
          The <span>GOAT Life NFTs</span> are a collection of{" "}
          <span>10k unique</span> ERC-721 tokens living on the{" "}
          <span>Ethereum Blockchain</span> inspired by some of the greatest{" "}
          <span>athletes, entertainers, and heroes</span> of all time!
        </p>

        <p data-aos="fade-up">
          Each <span>GOAT Life consists of 157 properties</span> with varying
          rarities, from common to ultra rare. The more rare the GOAT, the more
          valuable your NFT and its rewards.
        </p>

        <div className="howMint" data-aos="fade-up">
          <HashLink to="/#whiteList">
            <div
              className={
                isDown === "btnDown"
                  ? "button button1 btnDown"
                  : "button button1"
              }
              onMouseDown={() => {
                handleDown("btnDown");
              }}
              onMouseUp={() => {
                handleUp("");
              }}
            >
              How to Mint
            </div>
          </HashLink>
        </div>
        <div className="mintWrapper">
          <div className="mintPart" data-aos="fade-left">
            {/* <h2>Coming Soon</h2> */}
            {showMint === false ? (
              <>
                <div className="countDown">
                  <h2>Time Till Mint</h2>
                  <p>Date: Mon, Mar 7th, 12:00 GMT</p>
                  <Timer
                    deadLine={1646654400}
                    setShowMint={() => {
                      setShowMint(true);
                    }}
                  />
                </div>
              </>
            ) : (
              <>
                <h2>MINT GOAT Life NFTs</h2>
                <div className="mintCount">
                  <button
                    className={
                      isDown === "decDwon"
                        ? "mintDecrease decDwon"
                        : "mintDecrease"
                    }
                    onMouseDown={() => {
                      handleDown("decDwon");
                    }}
                    onMouseUp={() => {
                      handleUp("");
                    }}
                    onClick={decreaseHandle}
                  >
                    <i className="fas fa-minus"></i>
                  </button>

                  <span className="mintCountValue" ref={numRef} style={{}}>
                    {mintCount}
                  </span>
                  <button
                    className={
                      isDown === "incDwon"
                        ? "mintIncrease incDwon"
                        : "mintIncrease"
                    }
                    onMouseDown={() => {
                      handleDown("incDwon");
                    }}
                    onMouseUp={() => {
                      handleUp("");
                    }}
                    onClick={increaseHandle}
                  >
                    <i className="fas fa-plus"></i>
                  </button>
                  <button
                    className={
                      isDown === "btnDwon"
                        ? "mintNow button mintDwon button1"
                        : "mintNow button button1"
                    }
                    onClick={mintTokens}
                    onMouseDown={() => {
                      handleDown("btnDwon");
                    }}
                    onMouseUp={() => {
                      handleUp("");
                    }}
                  >
                    MINT
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="imgList">
          <img
            src="assets/git_01.gif"
            alt=""
            data-aos="fade-right"
            onLoad={handleLoad}
          />
          <img
            src="assets/git_02.gif"
            alt=""
            data-aos="fade-up"
            onLoad={handleLoad}
          />
          <img
            src="assets/git_03.gif"
            alt=""
            data-aos="fade-left"
            onLoad={handleLoad}
          />
        </div>

        <h1 data-aos="fade-up">50% of All Sales Goes to the club</h1>
        <div data-aos="fade-up" className="line1"></div>

        <p data-aos="fade-up">
          <span>50% of ALL sales</span> are going back to the club…cuz that’s{" "}
          <span>what GOATs do… graze together!</span>
        </p>

        <p data-aos="fade-up">
          Whether it’s a <span>GOAT Life NFT sticker</span> or hoodie, Michael
          Jordan Rookie Card, Pokemon Unopened Box, Surfing with a Goat
          Experience or straight up crypto… each <span>GOAT Life NFT</span> will
          have a guaranteed buyback value…providing constant liquidity to owners
        </p>

        <p data-aos="fade-up">
          <span>The rarest GOAT</span> will have a{" "}
          <span>guaranteed buyback value of 10 ETH</span>, but holding on to it
          also will give the owner the largest, most frequent airdrops & free
          NFTs for being a <span>true GOAT of GOATs.</span>
        </p>

        <h1 data-aos="fade-up">
          {" "}
          <span>What will You Do?</span>
        </h1>
        <div className="howMint" data-aos="fade-up">
          <HashLink to="/#whiteList">
            <div
              className={
                isDown === "btnDown"
                  ? "button button1 btnDown"
                  : "button button1"
              }
              onMouseDown={() => {
                handleDown("btnDown");
              }}
              onMouseUp={() => {
                handleUp("");
              }}
            >
              How to Mint
            </div>
          </HashLink>
        </div>
      </div>
    </div>
  );
}
