import './home.scss'
import { useState } from 'react';
import {HashLink} from 'react-router-hash-link'

type Loading = {
    setIsLoading(value: boolean) : void
}
export default function Home({setIsLoading}:Loading) {

    const [isDown, setIsDown] = useState('')
    const handleDown=(value:string)=>{
        setIsDown(value)
    }
    const handleUp=(value:string)=>{
        setIsDown(value)
    }

    const handleLoad = ()=>{
        setIsLoading(false)
    }
    return (
        <div className="home" id = "home" >
            <div className="homeContent">
            <img src="assets/Goat-Life-Homepage.gif" alt="" onLoad={handleLoad} />
                <div className="warpper">

                </div>
                <div className="mintBellow">
                <HashLink to="/#mint">
                    <div
                    className={isDown === 'btnDown' ? "mintBtn button button1 btnDown":"mintBtn button button1"}
                    onMouseDown={()=>{handleDown('btnDown')}}
                    onMouseUp={()=>{handleUp('')}}
                    >
                        Mint Below
                    </div>
                </HashLink>
                </div>
            </div>
        </div>
    )
}
