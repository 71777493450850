import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import ConnectModal from "../connectModal/ConnectModal";
import "./topbar.scss";
import { HashLink } from "react-router-hash-link";
import { truncateWalletString } from "utils";
type MenuType = {
  menuOpen?: boolean;
  setMenuOpen(flag: boolean): void;
};
export default function Topbar({ menuOpen, setMenuOpen }: MenuType) {
  const [showConnectModal, setShowConnectModal] = useState(false);

  const [loginStatus, setLoginStatus] = useState(false);
  const { connector, library, chainId, account, active } = useWeb3React();
  useEffect(() => {
    const isLoggedin =
      account &&
      active &&
      chainId === parseInt(process.env.REACT_APP_NETWORK_ID, 10);
    setLoginStatus(isLoggedin);
  }, [connector, library, account, active, chainId]);

  const [isDown, setIsDown] = useState("");
  const handleDown = (value: string) => {
    setIsDown(value);
    console.log(isDown);
  };
  const handleUp = (value: string) => {
    setIsDown(value);
    console.log(isDown);
  };

  return (
    <div className="topbar">
      <div className="logo">
        <HashLink to="/#home" smooth>
          <img src="assets/logo_01.png" alt="" />
        </HashLink>
      </div>
      <div className="navList">
        <ul>
          <li className="color1">
            <HashLink to="/#home" smooth>
              Home
            </HashLink>
          </li>
          <li className="color2">
            <HashLink to="/#about" smooth>
              About
            </HashLink>
          </li>
          <li className="color2">
            <HashLink to="/#story" smooth>
              Story
            </HashLink>
          </li>
          <li className="color2">
            <HashLink to="/#mint" smooth>
              Mint
            </HashLink>
          </li>
          <li className="color2">
            <HashLink to="/#roadmap" smooth>
              Roadmap
            </HashLink>
          </li>
          <li className="color3">
            <HashLink to="/whiteList" smooth>
              WhiteList
            </HashLink>
          </li>
        </ul>
        <div className="btns">
          <div
            className={
              isDown === "btnDown"
                ? "connectBtn button1 button btnDown"
                : "connectBtn button1 button"
            }
            onMouseDown={() => {
              handleDown("btnDown");
            }}
            onMouseUp={() => {
              handleUp("");
            }}
            onClick={() => {
              !loginStatus && setShowConnectModal(true);
            }}
          >
            {loginStatus ? truncateWalletString(account) : "CONNECT WALLET"}
          </div>
        </div>
      </div>

      <div
        className={menuOpen ? "hamburger active" : "hamburger"}
        onClick={() => setMenuOpen(!menuOpen)}
      >
        <span className="line1"></span>
        <span className="line2"></span>
        <span className="line3"></span>
      </div>
      <ConnectModal
        showConnectModal={showConnectModal}
        setShowConnectModal={setShowConnectModal}
      />
    </div>
  );
}
