import { useState } from "react";
import "./whiteList.scss";
import Faq from "components/faq/Faq";
export default function WhiteList() {
  const [isDown, setIsDown] = useState("");
  const handleDown = (value: string) => {
    setIsDown(value);
  };
  const handleUp = (value: string) => {
    setIsDown(value);
  };

  return (
    <div className="whiteList">
      <div className="scroll" id="whiteList">
        {" "}
      </div>
      <div
        className="whiteListContent"
        style={{ backgroundImage: `url('assets/border_03.png')` }}
      >
        <div className="wrapper">
          <h1 data-aos="fade-up">WHITELIST</h1>
          <div data-aos="fade-up" className="line1"></div>

          <p data-aos="fade-up">
            <span>We will be giving away 100 GOATs to the early,</span> most
            trusted community members that help us on our way to becoming the
            Greatest Of All Time. All whitelisted members are guaranteed to mint
            at least <span>one free GOAT Life NFT</span> (gas fees only).
          </p>

          <Faq />

          <div className="howMint" data-aos="fade-up">
            <a href={`assets/r5-GOAT-presentation.pdf`} target={`blank`}>
              <div
                className={
                  isDown === "btnDown"
                    ? "button button1 btnDown"
                    : "button button1"
                }
                onMouseDown={() => {
                  handleDown("btnDown");
                }}
                onMouseUp={() => {
                  handleUp("");
                }}
              >
                Whitepaper
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
