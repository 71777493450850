import "./roadmap.scss";

export default function Roadmap() {
  return (
    <div className="roadmap">
      <div className="scroll" id="roadmap">
        {" "}
      </div>
      <div
        className="roadmapContent"
        style={{ backgroundImage: `url('assets/border_03.png')` }}
      >
        <div className="wrapper">
          <div className="title">
            <h1 data-aos="fade-up">Roadmap</h1>
          </div>
          <div data-aos="fade-up" className="line1"></div>
          <div className="node node1">
            <div className="imgContent">
              <img src="assets/node_01.png" alt="" />
            </div>
            <h3>PHASE 01</h3>
            <ul>
              <li>
                Develop artwork, smart contracts, and minting for website,{" "}
                <a href="/" target="_blank">
                  goatlife.io.
                </a>
              </li>
              <li>
                Grow Trading Community through organic social media and
                community building. Host contests, giveaways, and whitelisting{" "}
                <span>for free GOAT Life NFTs.</span>
              </li>
            </ul>
          </div>
          <div className="node node2">
            <div className="imgContent">
              <img src="assets/node_01.png" alt="" />
            </div>
            <h3>PHASE 02</h3>
            <ul>
              <li>
                Paid marketing campaign to increase awareness across social
                media.
              </li>
              <li>
                Continue to build Trading Community community with crypto
                content, games, and giveaways.
              </li>
              <li>
                Launch and mint 9000 remaining GOAT Life NFTs (0.1 mint fee).
                Rarities and future redemption values unlocked.
              </li>
              <li>Allow bot access to NFT holders.</li>
              <li>
                Develop GOAT Token, Staking platform, and Collection 2, a 3D
                GOAT with traits to be shared across the metaverse
              </li>
            </ul>
          </div>
          <div className="node node3">
            <div className="imgContent">
              <img src="assets/node_03.png" alt="" />
            </div>
            <h3>PHASE 03</h3>
            <ul>
              <li>
                90 days after sell out Guaranteed Buyback / Redemption
                available.
              </li>
              <li>GOAT Token and Staking go live.</li>
              <li>Donation to Surfing Goats Non-profit (33 ETH).</li>
              <li>Launch online merchandise store.</li>
            </ul>
          </div>
          <div className="node node2">
            <div className="imgContent">
              <img src="assets/node_04.png" alt="" />
            </div>
            <h3>PHASE 04</h3>
            <ul>
              <li>
                Launch and mint Collection 2, 10,000 more GOAT Life NFTs to
                build the community.
              </li>
              <li>
                Paid marketing campaign, rarities and future redemption values.
              </li>
              <li>Donation to non-profit.</li>
            </ul>
          </div>
          <div className="node node5">
            <div className="imgContent">
              <img src="assets/node_05.png" alt="" />
            </div>
            <h3>PHASE 05</h3>
            <ul>
              <li>
                Develop Metaverse GOAT Life avatars for transition into the
                metaverse.
              </li>
              <li>
                Set up GOAT Life in the Metaverse, store, pasture for GOATs to
                graze together, events, and more!
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
