import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import Expand from "react-expand-animated";
import "./faq.scss";
export default function Faq() {
  const [faqId, setFaqId] = useState(-1);
  const handleClick = (id: number) => {
    if (faqId !== -1) {
      if (faqId === id) {
        setFaqId(-1);
      } else {
        setFaqId(id);
      }
    } else {
      setFaqId(id);
    }
  };
  const isTabletOrMobile = useMediaQuery({
    query: "screen and (max-width: 768px) and (orientation:portrait)",
  });
  const isLandOrMobile = useMediaQuery({
    query: "screen and (max-height: 768px) and (orientation:landscape)",
  });
  useEffect(() => {
    if (isTabletOrMobile) {
    }

    if (isLandOrMobile) {
    }
    if (!isTabletOrMobile && !isLandOrMobile) {
    }
  }, [isLandOrMobile, isTabletOrMobile]);
  const transitions = ["height", "opacity", "background"];
  const styles = {
    open: { width: "100%" },
    close: { width: "100%" },
  };
  return (
    <div className="faq">
      <div className="scroll" id="faq"></div>
      <div className="faqContent">
        <div className="faqTitle">
          <h1>Early Opportunities:</h1>
        </div>
        <div className="faqWrapper">
          <div
            className="line"
            data-aos="fade-right"
            // style = {{height: faqId === 0? `auto` : `${h}rem`}}
          >
            <div className="question">
              <div
                className="circle"
                onClick={() => {
                  handleClick(0);
                }}
              >
                {faqId === 0 ? (
                  <i className="show fas fa-minus" />
                ) : (
                  <i className="show fas fa-plus" />
                )}
              </div>

              <p
                onClick={() => {
                  handleClick(0);
                }}
              >
                <span>
                  Reach Level 11 on the server by being active in the chat.
                </span>{" "}
                You can level up by actively talking in the Discord or by
                inviting pen. ople i<span>No spam please,</span> you will be
                removed.
              </p>
            </div>
            <Expand
              open={faqId === 0}
              duration={300}
              styles={styles}
              transitions={transitions}
            >
              <div className="answer">
                <a
                  href={`https://discord.gg/U3Zdpbzx6D`}
                  style={{ cursor: "pointer" }}
                >
                  <p>Join the conversation at our Discord page here!</p>
                </a>
              </div>
            </Expand>
          </div>

          <div
            className="line"
            data-aos="fade-right"
            // style = {{height: faqId === 1? `auto` : `${h}rem`}}
          >
            <div className="question">
              <div
                className="circle"
                onClick={() => {
                  handleClick(1);
                }}
              >
                {faqId === 1 ? (
                  <i className="show fas fa-minus" />
                ) : (
                  <i className="show fas fa-plus" />
                )}
              </div>

              <p>
                <span>Invite 10 people</span> to the server (the people you
                invite must stay in the server until we launch and verify their
                account).
              </p>
            </div>
            <Expand
              open={faqId === 1}
              duration={300}
              styles={styles}
              transitions={transitions}
            >
              <div className="answer">
                <p>
                  Invite your best friends, coworkers, school mates. Heck,
                  invite your mom! Get everyone in on the action!
                </p>
              </div>
            </Expand>
          </div>

          <div
            className="line"
            data-aos="fade-right"
            // style = {{height: faqId === 2? `auto` : `${h}rem`}}
          >
            <div className="question">
              <div
                className="circle"
                onClick={() => {
                  handleClick(2);
                }}
              >
                {faqId === 0 ? (
                  <i className="show fas fa-minus" />
                ) : (
                  <i className="show fas fa-plus" />
                )}
              </div>
              <p>
                <span>If you are an influencer</span> and want to promote the
                GOAT Life & the GOAT Lifestyle, <span>send a message</span> with
                your social media profiles to{" "}
                <span>GoatTradersNFT@gmail.com.</span> If you are chosen to be
                part of our influencer group, we will add you to the whitelist.
              </p>
            </div>
            <Expand
              open={faqId === 2}
              duration={300}
              styles={styles}
              transitions={transitions}
            >
              <div className="answer">
                <p>
                  We are great partners to those who can spread the good news
                  and get people along their way to being GOATs!
                </p>
              </div>
            </Expand>
          </div>
          <div
            className="line"
            data-aos="fade-right"
            // style = {{height: faqId === 3? `auto` : `${h}rem`}}
          >
            <div className="question">
              <div
                className="circle"
                onClick={() => {
                  handleClick(3);
                }}
              >
                {faqId === 3 ? (
                  <i className="show fas fa-minus" />
                ) : (
                  <i className="show fas fa-plus" />
                )}
              </div>
              <p>
                <span>Win one of our giveaways!</span>
              </p>
            </div>
            <Expand
              open={faqId === 3}
              duration={300}
              styles={styles}
              transitions={transitions}
            >
              <div className="answer">
                <p>
                  We are keeping the fun flowing with regular giveaways! Get in
                  with us and let the loot rain down!
                </p>
              </div>
            </Expand>
          </div>
        </div>
      </div>
    </div>
  );
}
