import "./menu.scss";
import { HashLink } from "react-router-hash-link";
type MenuType = {
  menuOpen: boolean;
  setMenuOpen(flag: boolean): void;
};

export default function Menu({ menuOpen, setMenuOpen }: MenuType) {
  return (
    <div className={"sidebar " + (menuOpen && "active")}>
      <ul>
        <li
          onClick={() => setMenuOpen(false)}
          className={"color1 menuItem1 " + (menuOpen && "active")}
        >
          <HashLink to="/#home">Home</HashLink>
        </li>
        <li
          onClick={() => setMenuOpen(false)}
          className={"color1 menuItem2 " + (menuOpen && "active")}
        >
          <HashLink to="/#about">About</HashLink>
        </li>
        <li
          onClick={() => setMenuOpen(false)}
          className={"color1 menuItem3 " + (menuOpen && "active")}
        >
          <HashLink to="/#story">Story</HashLink>
        </li>
        <li
          onClick={() => setMenuOpen(false)}
          className={"color3 menuItem4 " + (menuOpen && "active")}
        >
          <HashLink to="/#mint" smooth>
            Mint
          </HashLink>
        </li>
        <li
          onClick={() => setMenuOpen(false)}
          className={"color1 menuItem5 " + (menuOpen && "active")}
        >
          <HashLink to="/#roadmap">Roadmap</HashLink>
        </li>
        <li
          onClick={() => setMenuOpen(false)}
          className={"color5 menuItem5 " + (menuOpen && "active")}
        >
          <HashLink to="/whiteList" smooth>
            WhiteList
          </HashLink>
        </li>
      </ul>
    </div>
  );
}
